import { TypeAc3Enum } from '@/schema';

import { BillingCodeItem } from '../billing/billing-code-cell';

export const EducationCptCodeSupport = () => {
  return (
    <div className="grid max-w-80 justify-items-start gap-2 text-left">
      <div className="flex gap-2">
        <BillingCodeItem codeType={TypeAc3Enum.Support} noTooltip />

        <BillingCodeItem
          codeType={TypeAc3Enum.Support}
          isRepeatCode
          noTooltip
        />
      </div>

      <p>Reviewing and discussing RTM responses.</p>

      <div>
        <p>Requirements to bill:</p>

        <ul className="ml-6 grid list-disc">
          <li>Patient engaging in RTM responses.</li>
          <li>At least one synchronous interaction with the patient.</li>
          <li>
            Time in RTM data review + synchronous interaction (phone call, video
            call or in-person visit) with patient ≥ 20 minutes.
          </li>
          <li>98981: Each additional 20 minutes of data review.</li>
        </ul>
      </div>
    </div>
  );
};
