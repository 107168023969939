/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `30` - 30
 * * `60` - 60
 * * `90` - 90
 * @export
 */
export const PatientRTMEnrollmentDurationEnum = {
  NUMBER_30: 30,
  NUMBER_60: 60,
  NUMBER_90: 90,
} as const;
export type PatientRTMEnrollmentDurationEnum =
  (typeof PatientRTMEnrollmentDurationEnum)[keyof typeof PatientRTMEnrollmentDurationEnum];

export function PatientRTMEnrollmentDurationEnumFromJSON(
  json: any,
): PatientRTMEnrollmentDurationEnum {
  return PatientRTMEnrollmentDurationEnumFromJSONTyped(json, false);
}

export function PatientRTMEnrollmentDurationEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatientRTMEnrollmentDurationEnum {
  return json as PatientRTMEnrollmentDurationEnum;
}

export function PatientRTMEnrollmentDurationEnumToJSON(
  value?: PatientRTMEnrollmentDurationEnum | null,
): any {
  return value as any;
}
