import {
  addDays,
  addMinutes,
  intlFormat,
  intlFormatDistance,
  isMatch,
  parse,
} from 'date-fns';
import { format, parse as fpParse, parseISO } from 'date-fns/fp';

const intlLongFormatOptions: Parameters<typeof intlFormat>[1] = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
};

const intlShortFormatOptions: Parameters<typeof intlFormat>[1] = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
};

const isoDateOnlyFormat = 'yyyy-MM-dd';

export const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const offsetTimezoneToLocal = (date: Date) =>
  addMinutes(date, date.getTimezoneOffset());

export const parseMonth = fpParse(new Date(), 'yyyy-MM');

export const parseDate = fpParse(new Date(), isoDateOnlyFormat);

export const formatMonth = format('MMMM yyyy');

export const readableMonth = (date: string) => formatMonth(parseMonth(date));

export const formatDateShort = (date: Date) =>
  intlFormat(date, intlShortFormatOptions);

export const formatISODateShort = (isoDate: string) =>
  formatDateShort(parseISO(isoDate));

export const formatDateLong = (date: Date) =>
  intlFormat(date, intlLongFormatOptions);

export const formatISODateLong = (isoDate: string) =>
  formatDateLong(parseISO(isoDate));

export const dateDaysFromDate = (days: number, date = new Date()) =>
  addDays(date, days);

export const formatDateDaysFromDate = (days: number, date = new Date()) =>
  formatDateShort(dateDaysFromDate(days, date));

export const relativeFormatDaysFromDate = (
  days: number,
  baseDate = new Date(),
) =>
  intlFormatDistance(dateDaysFromDate(days, baseDate), baseDate, {
    unit: 'day',
  });

const usDashFormat = 'MM-dd-yyyy';
const usSlashFormat = 'MM/dd/yyyy';

/**
 * Reformats the date into ISO format, offset to the local timezone
 * @param date Date of birth to reformat
 * @returns string ISO date
 */
export const reFormatDobToISODate = (date: string) => {
  const isIsoMatch = isMatch(date, isoDateOnlyFormat);
  if (isIsoMatch) {
    return date;
  }

  const isDashMatch = isMatch(date, usDashFormat);
  if (isDashMatch) {
    const dashDate = parse(date, usDashFormat, new Date());
    return format(isoDateOnlyFormat, dashDate);
  }

  const isSlashMatch = isMatch(date, usSlashFormat);

  if (isSlashMatch) {
    const slashDate = parse(date, usSlashFormat, new Date());
    return format(isoDateOnlyFormat, slashDate);
  }

  throw new Error('Invalid date format');
};
