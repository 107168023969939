import { Outlet } from 'react-router';

export const BridgeTileLayout = () => {
  return (
    <div className="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-gray-800">
      <Outlet />
    </div>
  );
};

export const BridgeTargetedTileLayout = () => {
  return (
    <div className="flex size-10 items-center justify-center rounded-full bg-sky-600">
      <Outlet />
    </div>
  );
};
