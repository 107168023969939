/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { BaseBillingInstance } from './BaseBillingInstance';
import {
  BaseBillingInstanceFromJSON,
  BaseBillingInstanceFromJSONTyped,
  BaseBillingInstanceToJSON,
} from './BaseBillingInstance';
import type { CancellationReasonTypeE44Enum } from './CancellationReasonTypeE44Enum';
import {
  CancellationReasonTypeE44EnumFromJSON,
  CancellationReasonTypeE44EnumFromJSONTyped,
  CancellationReasonTypeE44EnumToJSON,
} from './CancellationReasonTypeE44Enum';
import type { InteractionLog } from './InteractionLog';
import {
  InteractionLogFromJSON,
  InteractionLogFromJSONTyped,
  InteractionLogToJSON,
} from './InteractionLog';
import type { PatientSerializerSlim } from './PatientSerializerSlim';
import {
  PatientSerializerSlimFromJSON,
  PatientSerializerSlimFromJSONTyped,
  PatientSerializerSlimToJSON,
} from './PatientSerializerSlim';
import type { PendingReasonsEnum } from './PendingReasonsEnum';
import {
  PendingReasonsEnumFromJSON,
  PendingReasonsEnumFromJSONTyped,
  PendingReasonsEnumToJSON,
} from './PendingReasonsEnum';
import type { ProviderSerializerSlim } from './ProviderSerializerSlim';
import {
  ProviderSerializerSlimFromJSON,
  ProviderSerializerSlimFromJSONTyped,
  ProviderSerializerSlimToJSON,
} from './ProviderSerializerSlim';
import type { RTMCycle } from './RTMCycle';
import {
  RTMCycleFromJSON,
  RTMCycleFromJSONTyped,
  RTMCycleToJSON,
} from './RTMCycle';
import type { StatusB89Enum } from './StatusB89Enum';
import {
  StatusB89EnumFromJSON,
  StatusB89EnumFromJSONTyped,
  StatusB89EnumToJSON,
} from './StatusB89Enum';
import type { TypeAc3Enum } from './TypeAc3Enum';
import {
  TypeAc3EnumFromJSON,
  TypeAc3EnumFromJSONTyped,
  TypeAc3EnumToJSON,
} from './TypeAc3Enum';
import type { WillChargePatientE4bEnum } from './WillChargePatientE4bEnum';
import {
  WillChargePatientE4bEnumFromJSON,
  WillChargePatientE4bEnumFromJSONTyped,
  WillChargePatientE4bEnumToJSON,
} from './WillChargePatientE4bEnum';

/**
 *
 * @export
 * @interface PatchedBillingInstance
 */
export interface PatchedBillingInstance {
  /**
   *
   * @type {string}
   * @memberof PatchedBillingInstance
   */
  readonly uuid?: string;
  /**
   *
   * @type {StatusB89Enum}
   * @memberof PatchedBillingInstance
   */
  readonly status?: StatusB89Enum;
  /**
   *
   * @type {Date}
   * @memberof PatchedBillingInstance
   */
  billableOn?: Date;
  /**
   *
   * @type {Date}
   * @memberof PatchedBillingInstance
   */
  reconciled?: Date;
  /**
   *
   * @type {ProviderSerializerSlim}
   * @memberof PatchedBillingInstance
   */
  readonly reconciledBy?: ProviderSerializerSlim;
  /**
   *
   * @type {TypeAc3Enum}
   * @memberof PatchedBillingInstance
   */
  readonly type?: TypeAc3Enum;
  /**
   *
   * @type {RTMCycle}
   * @memberof PatchedBillingInstance
   */
  readonly rtmcycle?: RTMCycle;
  /**
   *
   * @type {PatientSerializerSlim}
   * @memberof PatchedBillingInstance
   */
  readonly patient?: PatientSerializerSlim;
  /**
   *
   * @type {number}
   * @memberof PatchedBillingInstance
   */
  unitsOf98981?: number;
  /**
   *
   * @type {number}
   * @memberof PatchedBillingInstance
   */
  unitsOf99426?: number;
  /**
   *
   * @type {number}
   * @memberof PatchedBillingInstance
   */
  unitsOf99427?: number;
  /**
   *
   * @type {Array<InteractionLog>}
   * @memberof PatchedBillingInstance
   */
  readonly interactionLogs?: Array<InteractionLog>;
  /**
   *
   * @type {Date}
   * @memberof PatchedBillingInstance
   */
  calendarMonth?: Date;
  /**
   * Will charge the patient for this billing
   *
   * * `YES` - Yes
   * * `NO` - No
   * * `MAYBE` - Maybe
   * @type {WillChargePatientE4bEnum}
   * @memberof PatchedBillingInstance
   */
  willChargePatient?: WillChargePatientE4bEnum;
  /**
   *
   * @type {CancellationReasonTypeE44Enum}
   * @memberof PatchedBillingInstance
   */
  cancellationReasonType?: CancellationReasonTypeE44Enum;
  /**
   *
   * @type {string}
   * @memberof PatchedBillingInstance
   */
  optionalCancellationReasonMessage?: string;
  /**
   *
   * @type {Date}
   * @memberof PatchedBillingInstance
   */
  cancelledOn?: Date;
  /**
   *
   * @type {ProviderSerializerSlim}
   * @memberof PatchedBillingInstance
   */
  readonly cancelledBy?: ProviderSerializerSlim;
  /**
   *
   * @type {BaseBillingInstance}
   * @memberof PatchedBillingInstance
   */
  readonly prevBillinginstance?: BaseBillingInstance;
  /**
   *
   * @type {Array<PendingReasonsEnum>}
   * @memberof PatchedBillingInstance
   */
  readonly pendingReasons?: Array<PendingReasonsEnum>;
}

/**
 * Check if a given object implements the PatchedBillingInstance interface.
 */
export function instanceOfPatchedBillingInstance(value: object): boolean {
  return true;
}

export function PatchedBillingInstanceFromJSON(
  json: any,
): PatchedBillingInstance {
  return PatchedBillingInstanceFromJSONTyped(json, false);
}

export function PatchedBillingInstanceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedBillingInstance {
  if (json == null) {
    return json;
  }
  return {
    uuid: json['uuid'] == null ? undefined : json['uuid'],
    status:
      json['status'] == null
        ? undefined
        : StatusB89EnumFromJSON(json['status']),
    billableOn:
      json['billable_on'] == null ? undefined : new Date(json['billable_on']),
    reconciled:
      json['reconciled'] == null ? undefined : new Date(json['reconciled']),
    reconciledBy:
      json['reconciled_by'] == null
        ? undefined
        : ProviderSerializerSlimFromJSON(json['reconciled_by']),
    type: json['type'] == null ? undefined : TypeAc3EnumFromJSON(json['type']),
    rtmcycle:
      json['rtmcycle'] == null ? undefined : RTMCycleFromJSON(json['rtmcycle']),
    patient:
      json['patient'] == null
        ? undefined
        : PatientSerializerSlimFromJSON(json['patient']),
    unitsOf98981:
      json['units_of_98981'] == null ? undefined : json['units_of_98981'],
    unitsOf99426:
      json['units_of_99426'] == null ? undefined : json['units_of_99426'],
    unitsOf99427:
      json['units_of_99427'] == null ? undefined : json['units_of_99427'],
    interactionLogs:
      json['interaction_logs'] == null
        ? undefined
        : (json['interaction_logs'] as Array<any>).map(InteractionLogFromJSON),
    calendarMonth:
      json['calendar_month'] == null
        ? undefined
        : new Date(json['calendar_month']),
    willChargePatient:
      json['will_charge_patient'] == null
        ? undefined
        : WillChargePatientE4bEnumFromJSON(json['will_charge_patient']),
    cancellationReasonType:
      json['cancellation_reason_type'] == null
        ? undefined
        : CancellationReasonTypeE44EnumFromJSON(
            json['cancellation_reason_type'],
          ),
    optionalCancellationReasonMessage:
      json['optional_cancellation_reason_message'] == null
        ? undefined
        : json['optional_cancellation_reason_message'],
    cancelledOn:
      json['cancelled_on'] == null ? undefined : new Date(json['cancelled_on']),
    cancelledBy:
      json['cancelled_by'] == null
        ? undefined
        : ProviderSerializerSlimFromJSON(json['cancelled_by']),
    prevBillinginstance:
      json['prev_billinginstance'] == null
        ? undefined
        : BaseBillingInstanceFromJSON(json['prev_billinginstance']),
    pendingReasons:
      json['pending_reasons'] == null
        ? undefined
        : (json['pending_reasons'] as Array<any>).map(
            PendingReasonsEnumFromJSON,
          ),
  };
}

export function PatchedBillingInstanceToJSON(
  value?: PatchedBillingInstance | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    billable_on:
      value['billableOn'] == null
        ? undefined
        : value['billableOn'].toISOString().substring(0, 10),
    reconciled:
      value['reconciled'] == null
        ? undefined
        : (value['reconciled'] as any).toISOString(),
    units_of_98981: value['unitsOf98981'],
    units_of_99426: value['unitsOf99426'],
    units_of_99427: value['unitsOf99427'],
    calendar_month:
      value['calendarMonth'] == null
        ? undefined
        : (value['calendarMonth'] as any).toISOString().substring(0, 10),
    will_charge_patient: WillChargePatientE4bEnumToJSON(
      value['willChargePatient'],
    ),
    cancellation_reason_type: CancellationReasonTypeE44EnumToJSON(
      value['cancellationReasonType'],
    ),
    optional_cancellation_reason_message:
      value['optionalCancellationReasonMessage'],
    cancelled_on:
      value['cancelledOn'] == null
        ? undefined
        : (value['cancelledOn'] as any).toISOString(),
  };
}
