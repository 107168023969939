import { throttle } from 'lodash-es';
import { type NavigateFunction } from 'react-router';
import { toast } from 'sonner';

import { getRouteWithParams } from '@/hooks/use-navigate-preserve-params';
import {
  API_BASE_URL,
  API_LOGIN_URL,
  API_LOGOUT_URL,
  PASSWORD_RESET_CONFIRM_URL,
  SIGNUP_URL,
  VERIFY_EMAIL_URL,
} from '@/old/config/api_routes';
import {
  identifyIntercom,
  shutdownIntercom,
} from '@/old/utils/thirdPartyAPIs/intercom';
import { SegmentIO } from '@/old/utils/thirdPartyAPIs/segment';
import {
  BRIDGE_PREFIX,
  BRIDGE_SSO_TILE_URL,
  BRIDGE_TILES_URLS,
  LOGIN_URL,
  PASSWORD_RESET_URL,
  PATIENT_LIST_URL,
} from '@/routes/app-routes';
import { type UnSerializedProvider, useAuthState } from '@/state/auth-state';

import { sendWithRetry } from './fetch-utils';
import { identifySentryUser } from './sentry';
import { tanstackErrorHandler } from './tanstack-client';

export const initAuthState = (provider: UnSerializedProvider) => {
  useAuthState.getState().setAuthState(provider);

  const { user } = provider;

  if (!user) {
    throw new Error('User data is missing in provider');
  }

  const fullName = `${user?.first_name} ${user?.last_name}`;

  identifyIntercom(user);
  SegmentIO.identify(user);
  identifySentryUser({
    id: user?.id,
    email: user?.email,
    fullName,
  });

  // Don't show the welcome back message on the bridge tiles
  if (
    !BRIDGE_TILES_URLS.some((url) => window.location.pathname.includes(url))
  ) {
    toast.success(`👋🏽 Welcome back ${fullName} 😀`);
  }
};

export const login = async (
  navigate: NavigateFunction,
  username: string,
  password: string,
) => {
  try {
    const data = await sendWithRetry<UnSerializedProvider>(
      `${API_BASE_URL}${API_LOGIN_URL}`,
      {
        // use below when gql is ready
        // username: username.toLowerCase(), // can provide username or email
        username: username.toLowerCase(), // can provide username or email despite having to say username
        password,
      },
    );

    initAuthState(data);

    navigate(PATIENT_LIST_URL);
  } catch (error) {
    tanstackErrorHandler(error as Error, 'Login failed');
  }
};

export const logout = throttle(async (wasInactive = false) => {
  try {
    shutdownIntercom();

    useAuthState.getState().clearAuthState();

    // If within Bridge, let the main SSO tile handle the logout
    if (
      location.pathname.includes(BRIDGE_PREFIX) &&
      location.pathname !== BRIDGE_SSO_TILE_URL
    ) {
      return;
    }

    await sendWithRetry(`${API_BASE_URL}${API_LOGOUT_URL}/`, {});

    if (wasInactive) {
      toast.info('Logged out due to inactivity');
    } else {
      toast.success('Logout Successful');
    }

    const params = location.search;

    const route = getRouteWithParams(LOGIN_URL, new URLSearchParams(params));

    history.pushState({}, '', route);
    location.reload();
  } catch (error) {
    tanstackErrorHandler(error as Error, 'Logout failed');
  }
}, 1e3);

export const resetPasswordRequest = async (email: string) => {
  try {
    await sendWithRetry(`${API_BASE_URL}${PASSWORD_RESET_URL}`, {
      email,
    });
  } catch (error) {
    tanstackErrorHandler(error as Error, 'Reset password failed');
  }
};

export const verifyEmail = async (
  navigate: NavigateFunction,
  verifyKey: string,
) => {
  try {
    await sendWithRetry(`${API_BASE_URL}${VERIFY_EMAIL_URL}`, {
      key: verifyKey,
    });

    toast.success('Email has been verifed');

    navigate(LOGIN_URL);
  } catch (error) {
    tanstackErrorHandler(error as Error, 'Email verification failed');
  }
};

export const signUp = async (
  navigate: NavigateFunction,
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  role: string | null,
  gReCaptcha: string,
  clinic: string | null,
) => {
  try {
    await sendWithRetry(`${API_BASE_URL}${SIGNUP_URL}/`, {
      user_type: role,
      username: email,
      password,
      email,
      first_name: firstName,
      last_name: lastName,
      'g-recaptcha-response': gReCaptcha,
      language: 'en',
      account_origin: 'gui_rf',
      default_clinic: clinic,
      created_by: null,
      phone: '', // backend API requires some phone value for any user creation or else 400
    });

    await login(navigate, email, password);
  } catch (error) {
    tanstackErrorHandler(error as Error, 'Signup failed');
  }
};

export const resetPassword = async (
  navigate: NavigateFunction,
  password: string,
  token: string,
) => {
  try {
    await sendWithRetry(`${API_BASE_URL}${PASSWORD_RESET_CONFIRM_URL}`, {
      password,
      token,
    });

    toast.success(
      'Password reset successful. Please login with your new password.',
    );

    navigate(LOGIN_URL);
  } catch (error) {
    tanstackErrorHandler(error as Error, 'Password reset failed');
  }
};
