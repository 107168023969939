import { isEmpty, isFinite } from 'lodash-es';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { BRIDGE_SSO_TILE_URL, BRIDGE_SSO_URL } from '@/routes/app-routes';

export type UnSerializedProvider = {
  token?: string;
  api?: {
    sendbird_session_token: string;
    sendbird_api_token: string;
    intercom_user_hash: string;
  };
  user?: {
    id: number;
    email: string;
    user_type: string;
    last_name: string;
    first_name: string;
  };
};

type RequiredAPI = Required<UnSerializedProvider>['api'];

interface AuthState {
  token?: UnSerializedProvider['token'];
  intercomUserHash?: RequiredAPI['intercom_user_hash'];
  sendbirdApiToken?: RequiredAPI['sendbird_api_token'];
  sendbirdSessionToken?: RequiredAPI['sendbird_session_token'];
  user?: UnSerializedProvider['user'];
  setAuthState: (provider: UnSerializedProvider) => void;
  clearAuthState: () => void;
}

export const useAuthState = create<AuthState>()(
  devtools(
    persist(
      (set) =>
        ({
          setAuthState: (provider) => {
            set({
              token: provider.token,
              intercomUserHash: provider.api?.intercom_user_hash,
              sendbirdApiToken: provider.api?.sendbird_api_token,
              sendbirdSessionToken: provider.api?.sendbird_session_token,
              user: provider.user,
            });
          },
          clearAuthState: () => {
            set({
              token: undefined,
              intercomUserHash: undefined,
              sendbirdApiToken: undefined,
              sendbirdSessionToken: undefined,
              user: undefined,
            });
            localStorage.removeItem('auth-state');
          },
        }) as AuthState,
      { name: 'auth-state' },
    ),
    { name: 'auth-state' },
  ),
);

export const selectLoggedUser = (state: AuthState) => state.user;

export const selectAuthToken = (state: AuthState) => state.token;

export const selectIntercomUserHash = (state: AuthState) =>
  state.intercomUserHash;

export const selectSendbirdApiToken = (state: AuthState) =>
  state.sendbirdApiToken;

export const selectSendbirdSessionToken = (state: AuthState) =>
  state.sendbirdSessionToken;

export const selectLoggedUserId = (state: AuthState) => state.user?.id;

export const selectLoggedUserIdStr = (state: AuthState) =>
  state.user?.id.toString();

export const selectLoggedUserEmail = (state: AuthState) => state.user?.email;

export const selectIsUserLogged = (state: AuthState) => {
  const token = selectAuthToken(state);
  const loggedUserId = selectLoggedUserId(state);

  return !isEmpty(token) && isFinite(loggedUserId);
};

if (
  location.pathname.includes(BRIDGE_SSO_URL) ||
  location.pathname.includes(BRIDGE_SSO_TILE_URL)
) {
  useAuthState.persist.clearStorage();
}
