import { Column, Table } from '@tanstack/react-table';
import {
  ArrowDownIcon,
  ArrowUpDownIcon,
  ArrowUpIcon,
  Columns4Icon,
} from 'lucide-react';
import type { ComponentProps, ReactNode } from 'react';

import { cn } from '@/utils/tailwind';

import { Button } from './ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';

interface DataTableColumnHeaderProps<TData, TValue>
  extends ComponentProps<'div'> {
  column: Column<TData, TValue>;
  table: Table<TData>;
  headerTitle: ReactNode;
}

export function DataTableColumnHeader<TData, TValue>({
  column,
  table,
  headerTitle,
  className,
}: DataTableColumnHeaderProps<TData, TValue>) {
  if (!column.getCanSort()) {
    return <div className={cn(className)}>{headerTitle}</div>;
  }

  const toggleSortHandler = column.getToggleSortingHandler();
  const isMultiSorted =
    column.getCanMultiSort() &&
    column.getIsSorted() &&
    table.getState().sorting.length > 1;
  const sortIndex = isMultiSorted && ` (${column.getSortIndex() + 1})`;

  return (
    <div className={cn('flex items-center space-x-2', className)}>
      <Button
        variant="ghost"
        size="sm"
        className="-mx-3 flex h-8 min-w-28 gap-2 whitespace-normal data-[state=open]:bg-accent"
        onClick={(ev) => {
          table.setPageIndex(0);
          toggleSortHandler?.(ev);
        }}
      >
        <span>
          {headerTitle}
          {sortIndex}
        </span>

        {column.getIsSorted() === 'desc' ? (
          <ArrowDownIcon className="size-4" />
        ) : column.getIsSorted() === 'asc' ? (
          <ArrowUpIcon className="size-4" />
        ) : (
          <ArrowUpDownIcon className="size-4" />
        )}
      </Button>
    </div>
  );
}

export const VisibilityColumnHeader = <TData,>(props: {
  table: Table<TData>;
}) => {
  const { table } = props;
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon">
          <Columns4Icon className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end">
        <DropdownMenuLabel>Visible columns:</DropdownMenuLabel>

        {table
          .getAllColumns()
          .filter((column) => column.getCanHide())
          .map((column) => (
            <DropdownMenuCheckboxItem
              key={column.id}
              className="capitalize"
              checked={column.getIsVisible()}
              onSelect={(ev) => {
                ev.preventDefault();
                column.toggleVisibility();
              }}
            >
              {column.columnDef.meta?.label ?? column.id}
            </DropdownMenuCheckboxItem>
          ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
