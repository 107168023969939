import { TypeAc3Enum } from '@/schema';

import { BillingCodeItem } from '../billing/billing-code-cell';

export const EducationCptCodeEngage = () => {
  return (
    <div className="grid justify-items-start gap-2 text-left">
      <BillingCodeItem codeType={TypeAc3Enum.Engage} noTooltip />

      <p>Daily monitoring for musculoskeletal system.</p>

      <div>
        <p>Requirements to bill:</p>

        <ul className="ml-6 grid list-disc">
          <li>Patient engages ≥ 16 days in 30 day cycle.</li>
          <li>Enrolled for 30+ days.</li>
        </ul>
      </div>
    </div>
  );
};
