import { MoonIcon, SunIcon, SunMoonIcon } from 'lucide-react';
import { create } from 'zustand';

export const themeMediaMatcherFactory = () =>
  window.matchMedia('(prefers-color-scheme: dark)');

export const ThemesEnum = {
  light: 'light',
  dark: 'dark',
  system: 'system',
} as const;

export const ThemeLabelsEnum = {
  light: 'Light',
  dark: 'Dark',
  system: 'System',
} as const;

export const ThemeIconsEnum = {
  light: SunIcon,
  dark: MoonIcon,
  system: SunMoonIcon,
} as const;

const themeStorageKey = 'theme';

export type Theme = (typeof ThemesEnum)[keyof typeof ThemesEnum];
export type ResolvedTheme = Exclude<Theme, 'system'>;

type ThemeState = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
  setLightTheme: () => void;
  setDarkTheme: () => void;
  setSystemTheme: () => void;
};

export const handleSystemTheme = (theme: Theme): ResolvedTheme => {
  if (theme !== ThemesEnum.system) {
    return theme;
  }

  return themeMediaMatcherFactory().matches
    ? ThemesEnum.dark
    : ThemesEnum.light;
};

export const useThemeState = create<ThemeState>((set) => {
  // Get the theme from localStorage or use the default 'system' theme
  const savedTheme = localStorage.getItem(themeStorageKey) as Theme | null;
  const initialTheme: Theme = savedTheme ?? ThemesEnum.system;

  return {
    theme: initialTheme,
    setTheme: (theme) => {
      set({ theme });
      localStorage.setItem(themeStorageKey, theme);
    },
    setLightTheme: () => {
      set({ theme: ThemesEnum.light });
      localStorage.setItem(themeStorageKey, ThemesEnum.light);
    },
    setDarkTheme: () => {
      set({ theme: ThemesEnum.dark });
      localStorage.setItem(themeStorageKey, ThemesEnum.dark);
    },
    setSystemTheme: () => {
      set({ theme: ThemesEnum.system });
      localStorage.removeItem(themeStorageKey);
    },
  };
});
