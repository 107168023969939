import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { BarChart2Icon, UsersRoundIcon, WalletIcon } from 'lucide-react';
import { Suspense, useMemo } from 'react';
import { Outlet } from 'react-router';

import {
  BILLING_URL,
  METRICS_URL,
  PATIENT_LIST_URL,
} from '@/routes/app-routes';
import { billingApi } from '@/state/api-instances';
import { selectLoggedUserIdStr, useAuthState } from '@/state/auth-state';
import { pluralize } from '@/utils/accessibility-utils';
import { cn } from '@/utils/tailwind';

import { BillingTab } from '../state/billing-state';
import { AppHeader } from './app-header';
import { BillingTabMapping } from './billing/billing-table-column-def';
import { SideNavTooltipLink } from './common/side-nav-tooltip-link';
import { Skeleton } from './ui/skeleton';

const billingCountInitialData = { count: 0, results: [] };

export const AppLayout = () => {
  const loggedUserIdStr = useAuthState(selectLoggedUserIdStr);

  const { data } = useQuery({
    queryKey: ['billingNotificationDot', loggedUserIdStr],
    queryFn: async ({ signal }) =>
      billingApi.billingBillinginstancesList(
        {
          ...BillingTabMapping[BillingTab.Ready],
          pageSize: 1,
          primaryProviderId: loggedUserIdStr,
        },
        { signal },
      ),
    enabled: !isEmpty(loggedUserIdStr),
    initialData: billingCountInitialData,
    placeholderData: billingCountInitialData,
    meta: {
      errorMessage: 'Error fetching billings count',
    },
  });

  const isIntercomVisible = useMemo(() => !isEmpty(window?.Intercom), []);

  return (
    <div className="flex h-full flex-col">
      <AppHeader />

      <div className={cn('grid grow overflow-hidden md:grid-cols-[auto,1fr]')}>
        <nav
          className="grid auto-rows-[min-content] items-start gap-1 border-r bg-card p-2 text-card-foreground max-md:hidden"
          role="navigation"
          aria-label="Main navigation"
        >
          <SideNavTooltipLink
            to={PATIENT_LIST_URL}
            label="Patients"
            Icon={UsersRoundIcon}
          />

          <SideNavTooltipLink
            to={BILLING_URL}
            label="Billing"
            Icon={WalletIcon}
            showNotificationDot={(data?.count ?? 0) > 0}
            tooltip={
              data?.count ? (
                <p>
                  <strong>Billing:</strong>
                  <br />
                  You have <strong>{data.count}</strong>{' '}
                  {pluralize(data.count, 'billing', 'billings')} ready to be
                  processed.
                </p>
              ) : undefined
            }
          />

          <SideNavTooltipLink
            to={METRICS_URL}
            label="Metrics"
            Icon={BarChart2Icon}
          />
        </nav>

        <main
          className={cn(
            'relative overflow-auto bg-muted-background p-4 lg:p-6',
            {
              'max-md:pb-16': isIntercomVisible,
            },
          )}
        >
          <Suspense fallback={<Skeleton className="size-full" />}>
            <Outlet />
          </Suspense>
        </main>
      </div>
    </div>
  );
};
