import type { UnSerializedProvider } from '@/state/auth-state';

declare global {
  interface Window {
    analytics: {
      identify: (
        userId: number,
        userProperties: Record<string, string>,
      ) => void;
      page: () => void;
    };
  }
}

export const SegmentIO = {
  identify: (user: UnSerializedProvider['user']) => {
    if (!user) return;

    window.analytics.identify(user?.id, {
      username: user?.email,
    });
  },
  page: () => {
    if (window.analytics) window.analytics.page();
  },
} as const;
