import {
  API_BASE_URL,
  API_LOGIN_URL,
  API_LOGOUT_URL,
} from '@/old/config/api_routes';
import {
  AccountApi,
  BillingApi,
  PatientApi,
  PtApi,
  VendorsApi,
} from '@/schema';
import { Configuration } from '@/schema/runtime';
import { logout } from '@/utils/auth-utils';
import { USER_TIMEZONE } from '@/utils/date-formatters';
import {
  UNAUTHORIZED_STATUS_CODES,
  UnauthorizedError,
  inputIncludes,
} from '@/utils/fetch-utils';

import { selectAuthToken, useAuthState } from './auth-state';

const apiConfig = new Configuration({
  basePath: API_BASE_URL,
  headers: {
    'Django-Timezone': `${USER_TIMEZONE}`,
  },
  accessToken() {
    return selectAuthToken(useAuthState.getState()) ?? '';
  },
  middleware: [
    {
      post: async ({ response, url }) => {
        // Logout user if unauthorized access, ignore if logout request
        if (
          response &&
          UNAUTHORIZED_STATUS_CODES.includes(response.status) &&
          inputIncludes(url, API_BASE_URL) &&
          !inputIncludes(url, API_LOGOUT_URL) &&
          !inputIncludes(url, API_LOGIN_URL)
        ) {
          await logout(true);
          throw new UnauthorizedError();
        }
      },
    },
  ],
});

export const billingApi = new BillingApi(apiConfig);

export const providerMetadataApi = new PtApi(apiConfig);

export const accountApi = new AccountApi(apiConfig);

export const vendorsApi = new VendorsApi(apiConfig);

export const patientApi = new PatientApi(apiConfig);
