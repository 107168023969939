import { toast } from 'sonner';

import { ResponseError } from '@/schema';
import { ResponseError as FetchResponseError } from '@/utils/fetch-utils';

/**
 *
 * @export
 * @interface ParseError
 */
export interface ParseError {
  /**
   *
   * @type {string}
   * @memberof ParseError
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ParseError
   */
  detail: string;
  /**
   *
   * @type {string}
   * @memberof ParseError
   */
  attr: string | null;
}

/**
 *
 * @export
 * @interface ParseErrorResponse
 */
export type ResponseErrorPayload =
  | {
      /**
       *
       * @type {string}
       * @memberof ParseErrorResponse
       */
      type: string;
      /**
       *
       * @type {Array<ParseError>}
       * @memberof ParseErrorResponse
       */
      errors: Array<ParseError>;
    }
  | { message: string };

export function getErrorDetail(payload: ResponseErrorPayload): string {
  return 'message' in payload ? payload.message : payload?.errors?.[0]?.detail;
}

export async function handleResponseError(
  error: unknown,
  errorToastLabel = 'Request failed',
): Promise<boolean> {
  console.error('Request error:', error);

  if (
    (error instanceof ResponseError || error instanceof FetchResponseError) &&
    error.response.status !== 500
  ) {
    toast.error(errorToastLabel, {
      description:
        getErrorDetail(await error.response.json()) ??
        'If the problem persists please contact support',
      duration: Infinity,
      closeButton: true,
    });
    return true;
  }

  return false;
}
