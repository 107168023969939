import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  BarChart2Icon,
  CircleHelpIcon,
  CircleUserIcon,
  GraduationCapIcon,
  LogOutIcon,
  MenuIcon,
  MoonIcon,
  SunIcon,
  SunMoon,
  UsersRoundIcon,
  WalletIcon,
} from 'lucide-react';
import { useCallback, useState } from 'react';
import { Link, NavLink, To } from 'react-router';

import { useHideMobileIntercomDialogOpen } from '@/old/utils/thirdPartyAPIs/intercom';
import {
  BILLING_URL,
  METRICS_URL,
  PATIENT_LIST_URL,
  SUPPORT_URL,
} from '@/routes/app-routes';
import { useAuthState } from '@/state/auth-state';
import { useBillingState } from '@/state/billing-state';
import { useFilterState } from '@/state/filter-state';
import { usePatientListState } from '@/state/patient-list-state';
import { useTableState } from '@/state/table-state';
import {
  ThemeIconsEnum,
  ThemeLabelsEnum,
  useThemeState,
} from '@/state/theme-state';
import { logout } from '@/utils/auth-utils';

import { EducationGlobalDialog } from './common/education-global-dialog';
import { Button } from './ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from './ui/sheet';

const clearStores = () => {
  useAuthState.persist.clearStorage();
  useTableState.persist.clearStorage();
  useFilterState.persist.clearStorage();
  useBillingState.persist.clearStorage();
  usePatientListState.persist.clearStorage();
};

interface SheetNavLinkProps {
  'aria-label': string;
  children: React.ReactNode;
  to: To;
}

const SheetNavLink = ({
  'aria-label': ariaLabel,
  children,
  to,
}: SheetNavLinkProps) => (
  <NavLink to={to} aria-label={ariaLabel}>
    {({ isActive }) => (
      <Button
        variant={isActive ? 'secondary' : 'ghost'}
        className="flex w-full justify-start gap-2"
      >
        {children}
      </Button>
    )}
  </NavLink>
);

export const AppHeader = () => {
  const { inactivityLogoutEnabled } = useFlags();
  const { theme, setLightTheme, setDarkTheme, setSystemTheme } =
    useThemeState();

  const [open, setOpen] = useState(false);
  useHideMobileIntercomDialogOpen(open);

  const ThemeIcon = ThemeIconsEnum[theme];

  const handleLogout = useCallback(() => {
    clearStores();
    logout();
  }, []);

  const inactivityLogout = useCallback(() => {
    const storageSession = localStorage.getItem('auth-state');
    if (!storageSession) {
      return;
    }
    const session = JSON.parse(storageSession);

    session.state.token = session.state.token.slice(1);

    localStorage.setItem('auth-state', JSON.stringify(session));

    window.location.reload();
  }, []);

  return (
    <header
      data-testid="app-header"
      className="sticky top-0 flex w-full border bg-card p-2 md:justify-between"
    >
      <Sheet open={open} onOpenChange={setOpen}>
        <SheetTrigger asChild>
          <Button variant="ghost" size="icon" className="shrink-0 md:hidden">
            <MenuIcon className="h-5 w-5" />
            <span className="sr-only">Toggle navigation menu</span>
          </Button>
        </SheetTrigger>

        <SheetContent side="left">
          <SheetHeader className="hidden">
            <SheetTitle>Navigation Menu</SheetTitle>
            <SheetDescription>List of pages to navigate</SheetDescription>
          </SheetHeader>

          <nav
            className="grid gap-2 text-lg font-medium"
            role="navigation"
            aria-label="Main navigation"
          >
            <NavLink
              to="/"
              // Force reload countermeasure to 3rd party auth issues: https://sara-health.slack.com/archives/C6B5YB59A/p1715726114979829?thread_ts=1715725325.303859&cid=C6B5YB59A
              reloadDocument
              className="flex items-center gap-2 md:gap-4"
              aria-label="Home route"
              onClick={clearStores}
            >
              <img src="sara-logo.svg" alt="logo" className="h-10 w-10" />
            </NavLink>

            <SheetNavLink to={PATIENT_LIST_URL} aria-label="Patients route">
              <UsersRoundIcon />

              <h2 className="scroll-m-20 text-xl font-medium tracking-widest">
                Patients
              </h2>
            </SheetNavLink>

            <SheetNavLink to={BILLING_URL} aria-label="Billing route">
              <WalletIcon />

              <h2 className="scroll-m-20 text-xl font-medium tracking-widest">
                Billing
              </h2>
            </SheetNavLink>

            <SheetNavLink to={METRICS_URL} aria-label="Metrics route">
              <BarChart2Icon />

              <h2 className="scroll-m-20 text-xl font-medium tracking-widest">
                Metrics
              </h2>
            </SheetNavLink>
          </nav>
        </SheetContent>
      </Sheet>

      <nav
        className="flex gap-4 max-md:grow"
        role="navigation"
        aria-label="Home navigation"
      >
        <Link
          to="/"
          // Force reload countermeasure to 3rd party auth issues: https://sara-health.slack.com/archives/C6B5YB59A/p1715726114979829?thread_ts=1715725325.303859&cid=C6B5YB59A
          reloadDocument
          className="flex items-center gap-2 md:gap-4"
          aria-label="Home route"
          onClick={clearStores}
        >
          <div className="flex h-10 w-10 justify-center">
            <img src="sara-logo.svg" alt="SaRA logo" />
          </div>
        </Link>
      </nav>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon" className="rounded-full">
            <CircleUserIcon className="h-5 w-5" />
            <span className="sr-only">Toggle user menu</span>
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent align="end">
          <Link
            to="https://sarahealth.notion.site/SaRA-Academy-e9f44f543a214d53a971eeefa1cf3c34"
            target="_blank"
            rel="noopener noreferrer"
          >
            <DropdownMenuItem className="flex gap-4">
              <GraduationCapIcon size={18} className="opacity-50" />
              SaRA Academy
            </DropdownMenuItem>
          </Link>

          <EducationGlobalDialog />

          <DropdownMenuSub>
            <DropdownMenuSubTrigger className="flex gap-4">
              <ThemeIcon size={18} className="opacity-50" />
              {ThemeLabelsEnum[theme]} Theme
            </DropdownMenuSubTrigger>

            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem
                  className="flex gap-4"
                  onClick={setLightTheme}
                >
                  <SunIcon size={18} className="opacity-50" />
                  Light Mode
                </DropdownMenuItem>

                <DropdownMenuItem className="flex gap-4" onClick={setDarkTheme}>
                  <MoonIcon size={18} className="opacity-50" />
                  Dark Mode
                </DropdownMenuItem>

                <DropdownMenuItem
                  className="flex gap-4"
                  onClick={setSystemTheme}
                >
                  <SunMoon />
                  System Mode
                </DropdownMenuItem>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>

          <Link to={SUPPORT_URL}>
            <DropdownMenuItem className="flex gap-4">
              <CircleHelpIcon size={18} className="opacity-50" />
              Support
            </DropdownMenuItem>
          </Link>

          <DropdownMenuItem className="flex gap-4" onClick={handleLogout}>
            <LogOutIcon size={18} className="opacity-50" />
            Logout
          </DropdownMenuItem>

          {inactivityLogoutEnabled && (
            <DropdownMenuItem className="flex gap-4" onClick={inactivityLogout}>
              <LogOutIcon size={18} className="opacity-50" />
              Inactivity Logout
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </header>
  );
};
