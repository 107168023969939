import { useEffect, useState } from 'react';

import {
  type ResolvedTheme,
  ThemesEnum,
  handleSystemTheme,
  themeMediaMatcherFactory,
  useThemeState,
} from '@/state/theme-state';

// This hook will sync the theme with the body class, we use it in the app or main components
export const useThemeSync = () => {
  const { theme } = useThemeState();

  useEffect(() => {
    const handleThemeChange = () => {
      const systemTheme = handleSystemTheme(theme);

      document.body.classList.remove(...Object.values(ThemesEnum));
      document.body.classList.add(systemTheme);
    };

    handleThemeChange();

    const matcher = themeMediaMatcherFactory();

    matcher.addEventListener('change', handleThemeChange);
    return () => {
      matcher.removeEventListener('change', handleThemeChange);
    };
  }, [theme]);
};

export const useReactiveTheme = (): ResolvedTheme => {
  const { theme } = useThemeState();
  const [currentTheme, setCurrentTheme] = useState(handleSystemTheme(theme));

  useEffect(() => {
    const handleThemeChange = () => {
      const systemTheme = handleSystemTheme(theme);

      setCurrentTheme(systemTheme);
    };

    handleThemeChange();

    const matcher = themeMediaMatcherFactory();

    matcher.addEventListener('change', handleThemeChange);
    return () => {
      matcher.removeEventListener('change', handleThemeChange);
    };
  }, [theme]);

  return currentTheme;
};
