import { InfoIcon } from 'lucide-react';
import { useState } from 'react';

import { useHideMobileIntercomDialogOpen } from '@/old/utils/thirdPartyAPIs/intercom';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import { DropdownMenuItem } from '../ui/dropdown-menu';
import { Separator } from '../ui/separator';
import { EducationCptCodeConnect } from './education-cpt-code-connect';
import { EducationCptCodeEngage } from './education-cpt-code-engage';
import { EducationCptCodeSupport } from './education-cpt-code-support';

const preventCloseHandler = (e: Event) => e.preventDefault();

export const EducationGlobalDialog = () => {
  const [open, setOpen] = useState(false);
  useHideMobileIntercomDialogOpen(open);

  return (
    <Dialog modal open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <DropdownMenuItem className="flex gap-4" onSelect={preventCloseHandler}>
          <InfoIcon size={18} className="opacity-50" />
          The CPT Codes
        </DropdownMenuItem>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>The CPT Codes</DialogTitle>

          <DialogDescription>
            Learn about the conditions for billing codes.
          </DialogDescription>
        </DialogHeader>

        <EducationCptCodeConnect />

        <Separator />

        <EducationCptCodeEngage />

        <Separator />

        <EducationCptCodeSupport />
      </DialogContent>
    </Dialog>
  );
};
