import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { Clinic, Provider, ProviderMetadata, Workflow } from '@/schema';

interface MetadataState {
  metadata?: ProviderMetadata;
  setMetadata: (metadata?: ProviderMetadata) => void;
}

export const useMetadataState = create<MetadataState>()(
  devtools(
    (set) => ({
      setMetadata: (metadata) => set({ metadata }),
    }),
    { name: 'metadata-state' },
  ),
);

export const selectMetadata = (state: MetadataState) => state.metadata;

export const selectCompany = (state: MetadataState) =>
  selectMetadata(state)?.company;

export const selectProviderMetadata = (state: MetadataState) =>
  selectMetadata(state)?.providerMetadata;

export const selectUser = (state: MetadataState) =>
  selectProviderMetadata(state)?.user;

export const selectUserId = (state: MetadataState) => selectUser(state)?.id;

export const selectDefaultClinic = (state: MetadataState) => {
  const defaultClinicName = selectUser(state)?.defaultClinic;

  return selectMetadata(state)?.company.clinics.find(
    (clinic) => clinic.name === defaultClinicName,
  );
};

export const selectDefaultWorkflow = (state: MetadataState) =>
  selectProviderMetadata(state)?.defaultWorkflow;

export const selectDefaultWorkflowTag = (state: MetadataState) =>
  selectDefaultWorkflow(state)?.rtmFlowTag;

const stableEmptyWorkflows: Workflow[] = [];
export const selectWorkflows = (state: MetadataState) =>
  selectProviderMetadata(state)?.workflows ?? stableEmptyWorkflows;

// Unstable reference, needs useShallow. https://zustand.docs.pmnd.rs/guides/prevent-rerenders-with-use-shallow
export const selectProviders = (state: MetadataState) =>
  [
    ...(selectMetadata(state)
      ?.company.clinics.flatMap((clinic) => clinic.providers)
      .reduce((acc, provider) => {
        if (!acc.has(provider.user.id) && provider.user.isActive) {
          acc.set(provider.user.id, provider.user);
        }

        return acc;
      }, new Map<Provider['user']['id'], Provider['user']>())
      .values() ?? []),
  ].sort((a, b) => a.fullName.localeCompare(b.fullName));

const stableEmptyClinics: Clinic[] = [];
export const selectClinics = (state: MetadataState) =>
  selectMetadata(state)?.company.clinics ?? stableEmptyClinics;

export const selectIsPcmEnabled = (state: MetadataState) =>
  selectCompany(state)?.isPcmEnabled;
