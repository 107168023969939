/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  AbstractMetrics,
  BillingBillinginstancesReconcileInBulkCreate201Response,
  BillingInstance,
  BillingInstanceBulkBill,
  BillingInstanceBulkCancel,
  PaginatedBillingInstanceList,
  PatchedBillingInstance,
  PatchedRTMEnrollment,
  RTMEnrollment,
} from '../models/index';
import {
  AbstractMetricsFromJSON,
  AbstractMetricsToJSON,
  BillingBillinginstancesReconcileInBulkCreate201ResponseFromJSON,
  BillingBillinginstancesReconcileInBulkCreate201ResponseToJSON,
  BillingInstanceBulkBillFromJSON,
  BillingInstanceBulkBillToJSON,
  BillingInstanceBulkCancelFromJSON,
  BillingInstanceBulkCancelToJSON,
  BillingInstanceFromJSON,
  BillingInstanceToJSON,
  PaginatedBillingInstanceListFromJSON,
  PaginatedBillingInstanceListToJSON,
  PatchedBillingInstanceFromJSON,
  PatchedBillingInstanceToJSON,
  PatchedRTMEnrollmentFromJSON,
  PatchedRTMEnrollmentToJSON,
  RTMEnrollmentFromJSON,
  RTMEnrollmentToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface BillingBillinginstancesCancelInBulkCreateRequest {
  billingInstanceBulkCancel: BillingInstanceBulkCancel;
}

export interface BillingBillinginstancesCreateRequest {
  billingInstance: BillingInstance;
}

export interface BillingBillinginstancesDestroyRequest {
  uuid: string;
}

export interface BillingBillinginstancesListRequest {
  billableOn?: Date;
  currentlyBillable?: boolean;
  ordering?: string;
  page?: number;
  pageSize?: number;
  patientBirthDate?: Date;
  patientId?: number;
  primaryProviderId?: string;
  search?: string;
  status?: BillingBillinginstancesListStatusEnum;
}

export interface BillingBillinginstancesPartialUpdateRequest {
  uuid: string;
  patchedBillingInstance?: PatchedBillingInstance;
}

export interface BillingBillinginstancesReconcileInBulkCreateRequest {
  billingInstanceBulkBill: BillingInstanceBulkBill;
}

export interface BillingBillinginstancesRetrieveRequest {
  uuid: string;
}

export interface BillingBillinginstancesUpdateRequest {
  uuid: string;
  billingInstance: BillingInstance;
}

export interface BillingMetricsListRequest {
  clinicUuid?: string;
  days?: number;
}

export interface BillingRtmenrollmentsCreateRequest {
  rTMEnrollment: RTMEnrollment;
}

export interface BillingRtmenrollmentsDestroyRequest {
  id: number;
}

export interface BillingRtmenrollmentsPartialUpdateRequest {
  id: number;
  patchedRTMEnrollment?: PatchedRTMEnrollment;
}

export interface BillingRtmenrollmentsRetrieveRequest {
  id: number;
}

export interface BillingRtmenrollmentsUpdateRequest {
  id: number;
  rTMEnrollment: RTMEnrollment;
}

/**
 *
 */
export class BillingApi extends runtime.BaseAPI {
  /**
   */
  async billingBillinginstancesCancelInBulkCreateRaw(
    requestParameters: BillingBillinginstancesCancelInBulkCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['billingInstanceBulkCancel'] == null) {
      throw new runtime.RequiredError(
        'billingInstanceBulkCancel',
        'Required parameter "billingInstanceBulkCancel" was null or undefined when calling billingBillinginstancesCancelInBulkCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/billing/billinginstances/cancel-in-bulk/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BillingInstanceBulkCancelToJSON(
          requestParameters['billingInstanceBulkCancel'],
        ),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async billingBillinginstancesCancelInBulkCreate(
    requestParameters: BillingBillinginstancesCancelInBulkCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.billingBillinginstancesCancelInBulkCreateRaw(
      requestParameters,
      initOverrides,
    );
  }

  /**
   */
  async billingBillinginstancesCreateRaw(
    requestParameters: BillingBillinginstancesCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BillingInstance>> {
    if (requestParameters['billingInstance'] == null) {
      throw new runtime.RequiredError(
        'billingInstance',
        'Required parameter "billingInstance" was null or undefined when calling billingBillinginstancesCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/billing/billinginstances/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BillingInstanceToJSON(requestParameters['billingInstance']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BillingInstanceFromJSON(jsonValue),
    );
  }

  /**
   */
  async billingBillinginstancesCreate(
    requestParameters: BillingBillinginstancesCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BillingInstance> {
    const response = await this.billingBillinginstancesCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async billingBillinginstancesDestroyRaw(
    requestParameters: BillingBillinginstancesDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['uuid'] == null) {
      throw new runtime.RequiredError(
        'uuid',
        'Required parameter "uuid" was null or undefined when calling billingBillinginstancesDestroy().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/billing/billinginstances/{uuid}/`.replace(
          `{${'uuid'}}`,
          encodeURIComponent(String(requestParameters['uuid'])),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async billingBillinginstancesDestroy(
    requestParameters: BillingBillinginstancesDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.billingBillinginstancesDestroyRaw(
      requestParameters,
      initOverrides,
    );
  }

  /**
   */
  async billingBillinginstancesListRaw(
    requestParameters: BillingBillinginstancesListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedBillingInstanceList>> {
    const queryParameters: any = {};

    if (requestParameters['billableOn'] != null) {
      queryParameters['billable_on'] = (requestParameters['billableOn'] as any)
        .toISOString()
        .substring(0, 10);
    }

    if (requestParameters['currentlyBillable'] != null) {
      queryParameters['currently_billable'] =
        requestParameters['currentlyBillable'];
    }

    if (requestParameters['ordering'] != null) {
      queryParameters['ordering'] = requestParameters['ordering'];
    }

    if (requestParameters['page'] != null) {
      queryParameters['page'] = requestParameters['page'];
    }

    if (requestParameters['pageSize'] != null) {
      queryParameters['page_size'] = requestParameters['pageSize'];
    }

    if (requestParameters['patientBirthDate'] != null) {
      queryParameters['patient_birth_date'] = (
        requestParameters['patientBirthDate'] as any
      )
        .toISOString()
        .substring(0, 10);
    }

    if (requestParameters['patientId'] != null) {
      queryParameters['patient_id'] = requestParameters['patientId'];
    }

    if (requestParameters['primaryProviderId'] != null) {
      queryParameters['primary_provider_id'] =
        requestParameters['primaryProviderId'];
    }

    if (requestParameters['search'] != null) {
      queryParameters['search'] = requestParameters['search'];
    }

    if (requestParameters['status'] != null) {
      queryParameters['status'] = requestParameters['status'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/billing/billinginstances/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedBillingInstanceListFromJSON(jsonValue),
    );
  }

  /**
   */
  async billingBillinginstancesList(
    requestParameters: BillingBillinginstancesListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedBillingInstanceList> {
    const response = await this.billingBillinginstancesListRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async billingBillinginstancesPartialUpdateRaw(
    requestParameters: BillingBillinginstancesPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BillingInstance>> {
    if (requestParameters['uuid'] == null) {
      throw new runtime.RequiredError(
        'uuid',
        'Required parameter "uuid" was null or undefined when calling billingBillinginstancesPartialUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/billing/billinginstances/{uuid}/`.replace(
          `{${'uuid'}}`,
          encodeURIComponent(String(requestParameters['uuid'])),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedBillingInstanceToJSON(
          requestParameters['patchedBillingInstance'],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BillingInstanceFromJSON(jsonValue),
    );
  }

  /**
   */
  async billingBillinginstancesPartialUpdate(
    requestParameters: BillingBillinginstancesPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BillingInstance> {
    const response = await this.billingBillinginstancesPartialUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async billingBillinginstancesReconcileInBulkCreateRaw(
    requestParameters: BillingBillinginstancesReconcileInBulkCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<BillingBillinginstancesReconcileInBulkCreate201Response>
  > {
    if (requestParameters['billingInstanceBulkBill'] == null) {
      throw new runtime.RequiredError(
        'billingInstanceBulkBill',
        'Required parameter "billingInstanceBulkBill" was null or undefined when calling billingBillinginstancesReconcileInBulkCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/billing/billinginstances/reconcile-in-bulk/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BillingInstanceBulkBillToJSON(
          requestParameters['billingInstanceBulkBill'],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BillingBillinginstancesReconcileInBulkCreate201ResponseFromJSON(
        jsonValue,
      ),
    );
  }

  /**
   */
  async billingBillinginstancesReconcileInBulkCreate(
    requestParameters: BillingBillinginstancesReconcileInBulkCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    BillingBillinginstancesReconcileInBulkCreate201Response | null | undefined
  > {
    const response = await this.billingBillinginstancesReconcileInBulkCreateRaw(
      requestParameters,
      initOverrides,
    );
    switch (response.raw.status) {
      case 201:
        return await response.value();
      case 204:
        return null;
      default:
        return await response.value();
    }
  }

  /**
   */
  async billingBillinginstancesRetrieveRaw(
    requestParameters: BillingBillinginstancesRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BillingInstance>> {
    if (requestParameters['uuid'] == null) {
      throw new runtime.RequiredError(
        'uuid',
        'Required parameter "uuid" was null or undefined when calling billingBillinginstancesRetrieve().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/billing/billinginstances/{uuid}/`.replace(
          `{${'uuid'}}`,
          encodeURIComponent(String(requestParameters['uuid'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BillingInstanceFromJSON(jsonValue),
    );
  }

  /**
   */
  async billingBillinginstancesRetrieve(
    requestParameters: BillingBillinginstancesRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BillingInstance> {
    const response = await this.billingBillinginstancesRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async billingBillinginstancesUpdateRaw(
    requestParameters: BillingBillinginstancesUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BillingInstance>> {
    if (requestParameters['uuid'] == null) {
      throw new runtime.RequiredError(
        'uuid',
        'Required parameter "uuid" was null or undefined when calling billingBillinginstancesUpdate().',
      );
    }

    if (requestParameters['billingInstance'] == null) {
      throw new runtime.RequiredError(
        'billingInstance',
        'Required parameter "billingInstance" was null or undefined when calling billingBillinginstancesUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/billing/billinginstances/{uuid}/`.replace(
          `{${'uuid'}}`,
          encodeURIComponent(String(requestParameters['uuid'])),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: BillingInstanceToJSON(requestParameters['billingInstance']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BillingInstanceFromJSON(jsonValue),
    );
  }

  /**
   */
  async billingBillinginstancesUpdate(
    requestParameters: BillingBillinginstancesUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BillingInstance> {
    const response = await this.billingBillinginstancesUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async billingMetricsListRaw(
    requestParameters: BillingMetricsListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<AbstractMetrics>>> {
    const queryParameters: any = {};

    if (requestParameters['clinicUuid'] != null) {
      queryParameters['clinic_uuid'] = requestParameters['clinicUuid'];
    }

    if (requestParameters['days'] != null) {
      queryParameters['days'] = requestParameters['days'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/billing/metrics/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(AbstractMetricsFromJSON),
    );
  }

  /**
   */
  async billingMetricsList(
    requestParameters: BillingMetricsListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<AbstractMetrics>> {
    const response = await this.billingMetricsListRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async billingRtmenrollmentsCreateRaw(
    requestParameters: BillingRtmenrollmentsCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<RTMEnrollment>> {
    if (requestParameters['rTMEnrollment'] == null) {
      throw new runtime.RequiredError(
        'rTMEnrollment',
        'Required parameter "rTMEnrollment" was null or undefined when calling billingRtmenrollmentsCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/billing/rtmenrollments/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RTMEnrollmentToJSON(requestParameters['rTMEnrollment']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RTMEnrollmentFromJSON(jsonValue),
    );
  }

  /**
   */
  async billingRtmenrollmentsCreate(
    requestParameters: BillingRtmenrollmentsCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RTMEnrollment> {
    const response = await this.billingRtmenrollmentsCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async billingRtmenrollmentsDestroyRaw(
    requestParameters: BillingRtmenrollmentsDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling billingRtmenrollmentsDestroy().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/billing/rtmenrollments/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async billingRtmenrollmentsDestroy(
    requestParameters: BillingRtmenrollmentsDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.billingRtmenrollmentsDestroyRaw(
      requestParameters,
      initOverrides,
    );
  }

  /**
   */
  async billingRtmenrollmentsListRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<RTMEnrollment>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/billing/rtmenrollments/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(RTMEnrollmentFromJSON),
    );
  }

  /**
   */
  async billingRtmenrollmentsList(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<RTMEnrollment>> {
    const response = await this.billingRtmenrollmentsListRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async billingRtmenrollmentsPartialUpdateRaw(
    requestParameters: BillingRtmenrollmentsPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<RTMEnrollment>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling billingRtmenrollmentsPartialUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/billing/rtmenrollments/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedRTMEnrollmentToJSON(
          requestParameters['patchedRTMEnrollment'],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RTMEnrollmentFromJSON(jsonValue),
    );
  }

  /**
   */
  async billingRtmenrollmentsPartialUpdate(
    requestParameters: BillingRtmenrollmentsPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RTMEnrollment> {
    const response = await this.billingRtmenrollmentsPartialUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async billingRtmenrollmentsRetrieveRaw(
    requestParameters: BillingRtmenrollmentsRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<RTMEnrollment>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling billingRtmenrollmentsRetrieve().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/billing/rtmenrollments/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RTMEnrollmentFromJSON(jsonValue),
    );
  }

  /**
   */
  async billingRtmenrollmentsRetrieve(
    requestParameters: BillingRtmenrollmentsRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RTMEnrollment> {
    const response = await this.billingRtmenrollmentsRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async billingRtmenrollmentsUpdateRaw(
    requestParameters: BillingRtmenrollmentsUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<RTMEnrollment>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling billingRtmenrollmentsUpdate().',
      );
    }

    if (requestParameters['rTMEnrollment'] == null) {
      throw new runtime.RequiredError(
        'rTMEnrollment',
        'Required parameter "rTMEnrollment" was null or undefined when calling billingRtmenrollmentsUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/billing/rtmenrollments/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: RTMEnrollmentToJSON(requestParameters['rTMEnrollment']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RTMEnrollmentFromJSON(jsonValue),
    );
  }

  /**
   */
  async billingRtmenrollmentsUpdate(
    requestParameters: BillingRtmenrollmentsUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RTMEnrollment> {
    const response = await this.billingRtmenrollmentsUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}

/**
 * @export
 */
export const BillingBillinginstancesListStatusEnum = {
  Blk: 'BLK',
  Cnc: 'CNC',
  Elg: 'ELG',
  Rec: 'REC',
} as const;
export type BillingBillinginstancesListStatusEnum =
  (typeof BillingBillinginstancesListStatusEnum)[keyof typeof BillingBillinginstancesListStatusEnum];
