import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { useEffect } from 'react';

import { providerMetadataApi } from '@/state/api-instances';
import {
  selectAuthToken,
  selectLoggedUserIdStr,
  useAuthState,
} from '@/state/auth-state';
import { useMetadataState } from '@/state/metadata-state';

export const useMetadataSync = () => {
  const loggedUserId = useAuthState(selectLoggedUserIdStr);
  const token = useAuthState(selectAuthToken);

  const { setMetadata } = useMetadataState();

  const { data } = useQuery({
    queryKey: ['metadata'],
    queryFn: ({ signal }) =>
      providerMetadataApi.ptProvidersRetrieve(
        { id: loggedUserId! },
        { signal },
      ),
    enabled: !isEmpty(loggedUserId) && !isEmpty(token),
    meta: {
      errorMessage: 'Error fetching metadata',
    },
  });

  useEffect(() => {
    if (data) {
      setMetadata(data);
    } else {
      setMetadata();
    }
  }, [data, setMetadata]);
};
