/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { PatientCareTeam } from './PatientCareTeam';
import {
  PatientCareTeamFromJSON,
  PatientCareTeamFromJSONTyped,
  PatientCareTeamToJSON,
} from './PatientCareTeam';
import type { PatientRTMEnrollment } from './PatientRTMEnrollment';
import {
  PatientRTMEnrollmentFromJSON,
  PatientRTMEnrollmentFromJSONTyped,
  PatientRTMEnrollmentToJSON,
} from './PatientRTMEnrollment';
import type { RTMCycle } from './RTMCycle';
import {
  RTMCycleFromJSON,
  RTMCycleFromJSONTyped,
  RTMCycleToJSON,
} from './RTMCycle';
import type { User } from './User';
import { UserFromJSON, UserFromJSONTyped, UserToJSON } from './User';

/**
 * Only intended for use in the schema view.
 * Not for actual use in the repo.
 * This is due to limitations where readonly fields end up being required upon POST ie current_intlog_secs.
 * @export
 * @interface PatientCreateSchema
 */
export interface PatientCreateSchema {
  /**
   *
   * @type {User}
   * @memberof PatientCreateSchema
   */
  user: User;
  /**
   *
   * @type {PatientCareTeam}
   * @memberof PatientCreateSchema
   */
  careTeam?: PatientCareTeam;
  /**
   *
   * @type {RTMCycle}
   * @memberof PatientCreateSchema
   */
  readonly currentRtmcycle: RTMCycle | null;
  /**
   *
   * @type {PatientRTMEnrollment}
   * @memberof PatientCreateSchema
   */
  rtmEnrollment?: PatientRTMEnrollment;
  /**
   *
   * @type {boolean}
   * @memberof PatientCreateSchema
   */
  isPcmEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof PatientCreateSchema
   */
  recordId?: string;
}

/**
 * Check if a given object implements the PatientCreateSchema interface.
 */
export function instanceOfPatientCreateSchema(value: object): boolean {
  if (!('user' in value)) return false;
  if (!('currentRtmcycle' in value)) return false;
  return true;
}

export function PatientCreateSchemaFromJSON(json: any): PatientCreateSchema {
  return PatientCreateSchemaFromJSONTyped(json, false);
}

export function PatientCreateSchemaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatientCreateSchema {
  if (json == null) {
    return json;
  }
  return {
    user: UserFromJSON(json['user']),
    careTeam:
      json['care_team'] == null
        ? undefined
        : PatientCareTeamFromJSON(json['care_team']),
    currentRtmcycle: RTMCycleFromJSON(json['current_rtmcycle']),
    rtmEnrollment:
      json['rtm_enrollment'] == null
        ? undefined
        : PatientRTMEnrollmentFromJSON(json['rtm_enrollment']),
    isPcmEnabled:
      json['is_pcm_enabled'] == null ? undefined : json['is_pcm_enabled'],
    recordId: json['record_id'] == null ? undefined : json['record_id'],
  };
}

export function PatientCreateSchemaToJSON(
  value?: PatientCreateSchema | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    user: UserToJSON(value['user']),
    care_team: PatientCareTeamToJSON(value['careTeam']),
    rtm_enrollment: PatientRTMEnrollmentToJSON(value['rtmEnrollment']),
    is_pcm_enabled: value['isPcmEnabled'],
    record_id: value['recordId'],
  };
}
