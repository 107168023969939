import { TypeAc3Enum } from '@/schema';

import { BillingCodeItem } from '../billing/billing-code-cell';

export const EducationCptCodeConnect = () => {
  return (
    <div className="grid justify-items-start gap-2 text-left">
      <BillingCodeItem codeType={TypeAc3Enum.Connect} noTooltip />

      <p>Initial set up and education on the RTM platform.</p>

      <div>
        <p>Requirements to bill:</p>

        <ul className="ml-6 grid list-disc">
          <li>Patient is educated/enrolled in SaRA.</li>
          <li>
            Patient is monitored for ≥ 16 days (i.e. patient does not opt out
            prior to day 16).
          </li>
        </ul>
      </div>
    </div>
  );
};
