/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `THERAPIST` - Therapist
 * * `SUPPORT_STAFF` - Support Staff
 * * `ADMIN` - Admin
 * @export
 */
export const ProviderTypeEnum = {
  Therapist: 'THERAPIST',
  SupportStaff: 'SUPPORT_STAFF',
  Admin: 'ADMIN',
} as const;
export type ProviderTypeEnum =
  (typeof ProviderTypeEnum)[keyof typeof ProviderTypeEnum];

export function ProviderTypeEnumFromJSON(json: any): ProviderTypeEnum {
  return ProviderTypeEnumFromJSONTyped(json, false);
}

export function ProviderTypeEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProviderTypeEnum {
  return json as ProviderTypeEnum;
}

export function ProviderTypeEnumToJSON(value?: ProviderTypeEnum | null): any {
  return value as any;
}
