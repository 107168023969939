import { useEffect } from 'react';

import { SCREENS, useMediaQuery } from '@/hooks/use-media-queries';
import {
  selectIntercomUserHash,
  selectLoggedUserEmail,
  selectLoggedUserId,
  useAuthState,
} from '@/state/auth-state';

export const intercomWrapper = (method) => {
  window?.Intercom(method);
};
export const showIntercom = () => {
  window.intercomSettings = {
    app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    hide_default_launcher: false,
  };
  window?.Intercom?.('update', window?.intercomSettings);
};

export const hideIntercom = () => {
  window.intercomSettings = {
    app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    hide_default_launcher: true,
  };
  window?.Intercom?.('update', window?.intercomSettings);
};

export const useHideMobileIntercomDialogOpen = (isDialogOpen = false) => {
  const isSmallScreen = useMediaQuery(SCREENS['max-lg']);

  // Hide Intercom in small screen when opened
  useEffect(() => {
    if (isSmallScreen && isDialogOpen) {
      hideIntercom();
    }

    return () => {
      showIntercom();
    };
  }, [isSmallScreen, isDialogOpen]);
};

const setLauncherSettings = (hideLauncher = false) => {
  window.intercomSettings = {
    app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    action_color: '#d8706b',
    background_color: '#0493c3',
    hide_default_launcher: hideLauncher,
    alignment: 'left',
  };
};
function getPrereqData() {
  const authState = useAuthState.getState();
  return {
    app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    user_id: selectLoggedUserId(authState),
    user_hash: selectIntercomUserHash(authState),
    email: selectLoggedUserEmail(authState),
  };
}
export const bootIntercom = () => {
  const intercomSettings = getPrereqData();
  if (window?.Intercom) window?.Intercom('boot', intercomSettings);
  setLauncherSettings(false);
};

const currentTime = parseInt(new Date().getTime() / 1000);

export const updateIntercom = () => {
  window?.Intercom?.('update', {
    last_request_at: currentTime,
  });
  setLauncherSettings(false);
};

export const shutdownIntercom = () => {
  window?.Intercom?.('shutdown', {
    last_request_at: currentTime,
  });
};

export function identifyIntercom(user) {
  const getIntercomAccountTypeLabel = (userType) => {
    if (userType === 'pt') return 'Physical Therapist';
    if (userType === 'patient') return 'Patient';
    return 'undef';
  };
  const identificationVerification = getPrereqData();
  const basicUserInfo = {
    username: user.username,
    account_type: getIntercomAccountTypeLabel(user.user_type),
    name: `${user.first_name} ${user.last_name}`,
    phone: user.phone,
    created: user.created,
  };

  const finalPayload = {
    ...identificationVerification,
    ...basicUserInfo,
  };
  window?.Intercom?.('update', finalPayload);
}
