import { useMemo } from 'react';
import { IMask } from 'react-imask';

import {
  ExtendDurationEnum,
  type Patient,
  type PatientSerializerSlim,
} from '@/schema';
import { formatDateShort } from '@/utils/date-formatters';

const IMaskPhonePipe = IMask.createPipe({
  mask: '+1 (000) 000-0000',
});

const NOT_PROVIDED = (
  <span className="text-muted-foreground">Not provided</span>
);

interface PatientInfoFragmentProps {
  patient: Patient | PatientSerializerSlim;
}

export const PatientInfoFragment = (props: PatientInfoFragmentProps) => {
  const { patient } = props;

  const phone = useMemo(() => {
    if (!patient.user.phone) {
      return NOT_PROVIDED;
    }

    return IMaskPhonePipe(patient.user.phone);
  }, [patient]);

  return (
    <>
      <div className="flex gap-2">
        <p>Phone:</p> {phone}
      </div>

      <div className="flex gap-2">
        <p>Type:</p>
        {patient.latestRtmenrollment?.smsWorkflowEnrollment?.workflow?.name ??
          'None'}
      </div>

      <div className="flex gap-2">
        <p>Birth Date:</p>
        {patient.user.birthDate
          ? formatDateShort(patient.user.birthDate)
          : NOT_PROVIDED}
      </div>

      <div className="flex gap-2">
        <p>Current Cycle:</p>
        {patient.currentRtmcycle ? (
          <>
            {patient.currentRtmcycle.cycleNumber} of{' '}
            {(patient.latestRtmenrollment?.duration ??
              ExtendDurationEnum.NUMBER_30) / 30}
          </>
        ) : (
          'Not currently enrolled.'
        )}
      </div>
    </>
  );
};
