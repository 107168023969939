import { Navigate, Outlet, useLocation } from 'react-router';

import { getRouteWithParams } from '@/hooks/use-navigate-preserve-params';
import { LOGIN_URL } from '@/routes/app-routes';
import { selectAuthToken, useAuthState } from '@/state/auth-state';

const PrivateRoute = () => {
  const location = useLocation();
  const token = useAuthState(selectAuthToken);

  if (!token) {
    const state = { from: location.pathname };
    const route = getRouteWithParams(
      LOGIN_URL,
      new URLSearchParams(location.search),
    );
    return <Navigate to={route} state={state} />;
  }

  return <Outlet />;
};

export default PrivateRoute;
