import { useMemo } from 'react';

import { BillingInstance, TypeAc3Enum } from '@/schema';

import { Badge } from '../ui/badge';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltip';

export const billingCodeIconOptions = {
  lock: '🔒',
  check: '✅',
} as const;

type BillingCodeIconOptions =
  (typeof billingCodeIconOptions)[keyof typeof billingCodeIconOptions];

interface BillingCodeCellProps {
  codeType: TypeAc3Enum;
  noTooltip?: boolean;
  leftPadIcon?: BillingCodeIconOptions;
  isPcm?: boolean;
  isRepeatCode?: boolean;
  unitsOfRepeatCode?: BillingInstance['unitsOf98981'];
}

const billingInstanceCodeEnum = {
  [TypeAc3Enum.Connect]: {
    supportText: '🤝 Connect (98975)',
    tooltip: 'Patient monitored for 16 or more days.',
  },
  [TypeAc3Enum.Engage]: {
    supportText: '💬 Engage (98977)',
    tooltip: 'Patient engaged for 16 or more days.',
  },
  [TypeAc3Enum.Support]: (isRepeatCode?: boolean, isPcm?: boolean) => {
    const threshold = isPcm ? 30 : 20;
    const baseCode = isPcm ? '9942' : '9898';
    const repeatDigit = isPcm
      ? isRepeatCode
        ? '7'
        : '6'
      : isRepeatCode
        ? '1'
        : '0';

    return {
      supportText: `💪 Support (${baseCode}${repeatDigit})`,
      tooltip: `RTM data reviewed & discussed (${isRepeatCode ? 'additional' : 'first'} ${threshold} minutes)`,
    };
  },
} as const;

export const BillingCodeItem = ({
  isPcm,
  codeType,
  isRepeatCode,
  leftPadIcon,
  noTooltip,
}: BillingCodeCellProps) => {
  const { supportText, tooltip } =
    codeType === TypeAc3Enum.Support
      ? billingInstanceCodeEnum[codeType](isRepeatCode, isPcm)
      : billingInstanceCodeEnum[codeType];

  const content = useMemo(() => {
    return (
      <>
        {leftPadIcon}{' '}
        <Badge variant="secondary" className="rounded-md">
          {supportText}
        </Badge>
      </>
    );
  }, [leftPadIcon, supportText]);

  return noTooltip ? (
    <div>{content}</div>
  ) : (
    <Tooltip>
      <TooltipTrigger>{content}</TooltipTrigger>

      <TooltipContent>{tooltip}</TooltipContent>
    </Tooltip>
  );
};

export const BillingCodeCell = ({
  isPcm,
  codeType,
  noTooltip,
  leftPadIcon,
  unitsOfRepeatCode,
}: BillingCodeCellProps) => {
  return (
    <div className="flex flex-col items-start gap-1">
      <BillingCodeItem
        isPcm={isPcm}
        codeType={codeType}
        leftPadIcon={leftPadIcon}
        noTooltip={noTooltip}
      />

      {codeType === TypeAc3Enum.Support && (unitsOfRepeatCode ?? 0) > 0 && (
        <div className="flex gap-2">
          <BillingCodeItem
            isPcm={isPcm}
            codeType={codeType}
            isRepeatCode
            leftPadIcon={leftPadIcon}
            noTooltip={noTooltip}
          />
          <span className="text-muted-foreground/70">
            x {unitsOfRepeatCode}
          </span>
        </div>
      )}
    </div>
  );
};
