/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `THERAPIST` - Therapist
 * * `SUPPORT_STAFF` - Support Staff
 * * `ADMIN` - Admin
 * @export
 */
export const Type6efEnum = {
  Therapist: 'THERAPIST',
  SupportStaff: 'SUPPORT_STAFF',
  Admin: 'ADMIN',
} as const;
export type Type6efEnum = (typeof Type6efEnum)[keyof typeof Type6efEnum];

export function Type6efEnumFromJSON(json: any): Type6efEnum {
  return Type6efEnumFromJSONTyped(json, false);
}

export function Type6efEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Type6efEnum {
  return json as Type6efEnum;
}

export function Type6efEnumToJSON(value?: Type6efEnum | null): any {
  return value as any;
}
