import { isNumber } from 'lodash-es';
import { useCallback } from 'react';
import {
  type NavigateFunction,
  type NavigateOptions,
  type To,
  useNavigate,
  useSearchParams,
} from 'react-router';

export const PRESERVE_PARAMS = ['pid', 'pdopen'];

export const getRouteWithParams = (
  url: string,
  searchParams: URLSearchParams,
) => {
  const paramsToKeep = new URLSearchParams();
  PRESERVE_PARAMS.forEach((key) => {
    const value = searchParams.get(key);
    if (value) {
      paramsToKeep.append(key, value);
    }
  });

  const urlObject = new URL(url, window.location.origin);
  urlObject.search = searchParams.toString();

  return `${urlObject.pathname}${urlObject.search}`;
};

export const useNavigatePreserveParams = (): NavigateFunction => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const navigatePreserveParams = useCallback<NavigateFunction>(
    (to: To | number, options?: NavigateOptions) => {
      if (isNumber(to)) {
        navigate(to);
        return;
      }

      const route = getRouteWithParams(to as string, searchParams);

      navigate(route, { replace: true, ...options });
    },
    [navigate, searchParams],
  );

  return navigatePreserveParams;
};
